import 'hubspot';
import domReady from '../utility/domReady.js';

domReady(() => {
	const {
		hbspt: {
			forms: { create },
		},
		hubspotCss,
	} = window;
	const forms = document.querySelectorAll(
		'.is-hubspot [data-portal-id][data-form-id]'
	);
	if (!forms) {
		return;
	}
	forms.forEach((form) => {
		const id = form.id;
		const portalId = form.getAttribute('data-portal-id');
		const formId = form.getAttribute('data-form-id');
		create({
			css: '',
			// css: hubspotCss,
			cssRequired: false,
			formId,
			portalId,
			region: 'na1',
			target: `#${id}`,
		});
	});
});
