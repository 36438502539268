/**
 * Call a function when the document is ready
 *
 * @param {Function} fn - The function to call.
 * @return {Void}
 */
export default (fn) => {
	if (
		'interactive' === document.readyState ||
		'complete' === document.readyState
	) {
		return fn();
	}
	document.addEventListener('DOMContentLoaded', fn);
};
